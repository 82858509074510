
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { PageWithSlots } from '@api';
import collectSlots from '@api/interfaces/collectSlots';
import Page from '@components/organisms/Page';
import CanonicalLink from '@features/seo/CanonicalLink';
import { StoreInfoComponentDataType } from '@features/storepage/types';
import createSwrFallback, { defaultRevalidationInterval, getCmsPage, getComponentsSSR } from '@helpers/fallback';
import useCmsComponentGroup from '@hooks/useCmsComponentGroup';
import useCmsPage from '@hooks/useCmsPage';
import { GetStaticPropsContext } from 'next';
import useTranslation from 'next-translate/useTranslation';
import Head from 'next/head';
import { unstable_serialize } from 'swr';
interface Props {
    storeId: string;
    cmsPageId: string;
    canonicalUrl: string;
}
/**
 * @return stringified id array
 */
const getComponentIds = (cmsPage?: PageWithSlots) => {
    const { RightContent } = collectSlots(cmsPage?.slots, ['RightContent']);
    const rightContentComponents = (RightContent?.component || []) as MultiColumnContainerComponentType[];
    const columnContainerData = rightContentComponents[0].columnContainerData as ColumnContainerData | undefined;
    return columnContainerData?.cmsComponents;
};
const StorePage = ({ storeId, cmsPageId, canonicalUrl }: Props) => {
    const { cmsPage } = useCmsPage(cmsPageId);
    const componentIds = !!cmsPage ? getComponentIds(cmsPage) : undefined;
    const { data: storeComponents = [] } = useCmsComponentGroup(componentIds || '', storeId);
    const storeComponent = storeComponents?.filter((item): item is StoreInfoComponentDataType => 'store' in item)?.[0];
    const storeName = storeComponent?.store?.displayName;
    const { t } = useTranslation('store');
    const pageTitle = `${storeName}${t('common:seo->defaultTitleSuffix')}`;
    return (<main>
      {storeName && (<Head>
          <title>{pageTitle}</title>
          <meta name="description" key="description" content={t('store->metaDescription', { store: storeName })}/>
        </Head>)}
      <CanonicalLink url={canonicalUrl}/>
      {cmsPage && <Page key={cmsPage.uid} cmsPage={cmsPage}/>}
    </main>);
};
export default StorePage;
// const translations = [...NAMESPACE_REQUIRED.TOOLBAR, ...NAMESPACE_REQUIRED.FOOTER, 'oldstore'];
export function getStaticPaths() {
    return {
        paths: [],
        fallback: 'blocking',
    };
}
async function getStaticProps({ params }: GetStaticPropsContext) {
    const baseProps = {};
    if (typeof params?.id !== 'string') {
        return { notFound: true, props: {} };
    }
    try {
        const storeId = params.id;
        const canonicalUrl = `${process.env.SERVER_API_HOST}/butik/${storeId}`;
        const cmsPageId = `/store-page/${storeId}`;
        const cmsPage = await getCmsPage(cmsPageId);
        const componentIds = getComponentIds(cmsPage as unknown as PageWithSlots);
        if (typeof componentIds !== 'string') {
            return { notFound: true, props: {} };
        }
        const components = await getComponentsSSR(componentIds, storeId);
        const fallback = await createSwrFallback({ pageType: 'ContentPage', label: cmsPageId });
        const swrComponents = {
            [unstable_serialize({
                endpoint: 'cms/components',
                storeId,
                componentIds,
                customerId: undefined,
                deliveryModeCode: '',
            })]: components,
        };
        return {
            props: {
                fallback: { ...fallback, ...swrComponents },
                storeId,
                cmsPageId,
                canonicalUrl,
            },
            revalidate: defaultRevalidationInterval,
        };
    }
    catch (error: unknown) {
        return { notFound: true, props: { ...baseProps } };
    }
}

    async function __Next_Translate__getStaticProps__194f457b195__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/butik/[id]',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194f457b195__ as getStaticProps }
  